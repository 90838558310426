<template>
  <q-form ref="editForm">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육대상자 정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <q-chip
            v-for="(item, idx) in items"
            :key="idx"
            :color="customColor(item, item.attendeesTypeCd)"
            text-color="white"
            icon="person_outline"
            :label="item.attendeesPositionName"
            :title="item.attendeesPositionName"
          >
          </q-chip>
        </template>
      </c-card>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="교육대상자"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="educationInfo.eduAttendeeList"
        :filtering="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :merge="grid.merge"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        :isExcelDown="false"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <!-- 삭제 -->
            <c-btn v-if="editable && !disabled && educationInfo.eduAttendeeList.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            <!-- 저장 -->
            <c-btn
              v-if="editable && !disabled && educationInfo.eduAttendeeList.length > 0"
              :url="insertUrl"
              :isSubmit="isSave1"
              :param="educationInfo.eduAttendeeList"
              mappingType="PUT"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveEducation1"
              @btnCallback="saveCallback1" 
            />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'click'">
            <q-btn 
              round unelevated 
              size="10px"
              color="amber" 
              icon="edit"
              @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
              >
              <q-popup-proxy :ref="'proxy1_' + props.rowIndex" :breakpoint="400">
                <component
                  :is="col.component"
                  :popupParam="props.row"
                  :rowIndex="props.rowIndex"
                  @signCallback="signCallback"
                />
              </q-popup-proxy>
            </q-btn>
          </template>
          <template v-if="col.name === 'vodPlayTime'">
            <q-chip
              :color="getPlayTimeColor(props.row.vodTotalTime)"
              outline square
              :clickable="false"
              text-color="white">
              {{getPlayTime(props.row)}}
            </q-chip>
          </template>
          <!-- <template v-if="col.name === 'electronSignature'">
            <div class="col-12">
              <q-img :src="props.row.electronSignature" fit="scale-down"
                :ratio="4/3"
                style="min-width: 100px; max-width: 150px">
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template> -->
        </template>
      </c-table>
    </div>
    <!--외부인력 교육대상자
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table4"
        title="LBL0002917"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :checkClickFlag="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        :isExcelDown="false"
        rowKey="attendeesNo"
        selection="multiple"
      > 
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem4" />
            <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem4" />
            <c-btn
              v-if="editable && !disabled && grid2.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave2"
              :param="grid2.data"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveEducation2"
              @btnCallback="saveCallback2" 
            />
          </q-btn-group>
        </template>
      </c-table>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sign">
      <!-- 이수자 명단 파일 -->
      <c-card title="LBL0002918" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable&&!disabled"
              label="LBL0002918">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-complete',
  props: {
    educationInfo: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
        eduQuestionFlag: 'N',
        educationMethodCd: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      }),
    },
    planUpdateBtnData: {
      title: 'LBLPLAN',
      flag: false,
      research: '',
      planResearch: '',
    },
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        searchResult: {
          flag: '',
          isSearch: false,
        },
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_COMPLETE_LIST',
        taskKey: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      vodGridColumn: [
        {
          name: 'attendeesDeptName',
          field: 'attendeesDeptName',
          style: 'width:80px',
          // 부서
          label: 'LBLDEPT',
          align: 'center',
          sortable: false,
        },
        {
          name: 'attendeesPositionName',
          field: 'attendeesPositionName',
          style: 'width:80px',
          // 직책
          label: 'LBLPOSITION',
          align: 'center',
          sortable: false,
        },
        {
          name: 'seniorityName',
          field: 'seniorityName',
          style: 'width:130px',
          label: '선임',
          align: 'center',
          sortable: false,
        },
        // {
        //  name: 'attendeesPositionName',
        //   field: 'attendeesPositionName',
        //   innerBtn: true,
        //   btns: [
        //     { label: '', icon: 'add', color: 'orange', tooltip: '대상자 추가' },
        //   ],
        //   style: 'width:150px',
        //   label: '부서/직책/법규선해임',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          name: 'attendeesName',
          field: 'attendeesName',
          // 대상자
          label: 'LBL0002893',
          style: 'width:100px',
          align: 'center',
          sortable: false
        },
        // {
        //   name: 'checkCompleteFlagName',
        //   field: 'checkCompleteFlagName',
        //   label: '이수여부',
        //   style: 'width:100px',
        //   align: 'center',
        //   sortable: false
        // },
        {
          name: 'checkCompleteFlag',
          field: 'checkCompleteFlag',
          // 이수여부
          label: 'LBL0002920',
          type: 'select',
          setHeader: true,
          style: 'width:100px',
          align: 'center',
          comboItems: [
            { code: 'Y', codeName: '이수' },
            { code: 'N', codeName: '미이수' },
          ],
          sortable: false,
        },
        {
          name: 'evaluationDate',
          field: 'evaluationDate',
          setHeader: true,
          // 이수일
          label: 'LBL0002868',
          type: 'date',
          style: 'width:110px',
          align: 'center',
          sortable: false
        },
        {
          name: 'click',
          field: 'click',
          // 서명
          label: 'LBL0002921',
          style: 'width:60px',
          type: 'custom',
          align: 'center',
          sortable: false,
          component: () => import(`${'./signaturePop.vue'}`)
        },
        {
          name: 'electronSignatureFlag',
          field: 'electronSignatureFlag',
          // 서명여부
          label: 'LBL0002922',
          style: 'width:80px',
          align: 'center',
          sortable: false,
        },
        {
          name: "vodPlayTime",
          field: "vodPlayTime",
          // 동영상 시청시간
          label: "LBL0002923",
          align: "center",
          style: 'width:180px',
          sortable: true,
          type: 'custom'
        },
        // {
        //   name: 'electronSignature',
        //   field: 'electronSignature',
        //   label: '전자서명',
        //   style: 'width:150px',
        //   type: 'custom',
        //   align: 'center',
        //   sortable: false,
        // },
        // {
        //   name: 'evaluationPoint',
        //   field: 'evaluationPoint',
        //   label: '평가점수',
        //   type: 'number',
        //   style: 'width:80px',
        //   align: 'center',
        //   sortable: false
        // },
        // {
        //   name: 'evaluationContent',
        //   field: 'evaluationContent',
        //   label: '평가내용',
        //   type: 'text',
        //   style: 'width:200px',
        //   align: 'center',
        //   sortable: false
        // },
        // {
        //   name: 'attendanceTime',
        //   field: 'attendanceTime',
        //   setHeader: true,
        //   label: '출석시간',
        //   style: 'width:100px',
        //   type: 'datetime',
        //   align: 'center',
        //   sortable: false
        // },
        {
          name: 'noAttendanceReason',
          field: 'noAttendanceReason',
          // 불참사유
          label: 'LBL0002869',
          type: 'text',
          align: 'left',
          sortable: false
        },
        {
          name: 'opinionContent',
          field: 'opinionContent',
          // 의견
          label: 'LBL0002925',
          type: 'text',
          align: 'left',
          sortable: false
        },
      ],
      grid: {
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:80px',
            // 부서
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:80px',
            // 직책
            label: 'LBLPOSITION',
            align: 'center',
            sortable: false,
          },
          {
            name: 'seniorityName',
            field: 'seniorityName',
            style: 'width:130px',
            label: '선임',
            align: 'center',
            sortable: false,
          },
          // {
          //  name: 'attendeesPositionName',
          //   field: 'attendeesPositionName',
          //   innerBtn: true,
          //   btns: [
          //     { label: '', icon: 'add', color: 'orange', tooltip: '대상자 추가' },
          //   ],
          //   style: 'width:150px',
          //   label: '부서/직책/법규선해임',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            // 대상자
            label: 'LBL0002893',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'checkCompleteFlag',
            field: 'checkCompleteFlag',
            // 이수여부
            label: 'LBL0002920',
            type: 'select',
            setHeader: true,
            style: 'width:100px',
            align: 'center',
            comboItems: [
              { code: 'Y', codeName: '이수' },
              { code: 'N', codeName: '미이수' },
            ],
            sortable: false,
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            setHeader: true,
            // 이수일
            label: 'LBL0002868',
            type: 'date',
            style: 'width:110px',
            align: 'center',
            sortable: false
          },
          {
            name: 'click',
            field: 'click',
            // 서명
            label: 'LBL0002921',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'./signaturePop.vue'}`)
          },
          {
            name: 'electronSignatureFlag',
            field: 'electronSignatureFlag',
            // 서명여부
            label: 'LBL0002922',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          // {
          //   name: 'electronSignature',
          //   field: 'electronSignature',
          //   label: '전자서명',
          //   style: 'width:150px',
          //   type: 'custom',
          //   align: 'center',
          //   sortable: false,
          // },
          // {
          //   name: 'evaluationPoint',
          //   field: 'evaluationPoint',
          //   label: '평가점수',
          //   type: 'number',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: false
          // },
          // {
          //   name: 'evaluationContent',
          //   field: 'evaluationContent',
          //   label: '평가내용',
          //   type: 'text',
          //   style: 'width:200px',
          //   align: 'center',
          //   sortable: false
          // },
          // {
          //   name: 'attendanceTime',
          //   field: 'attendanceTime',
          //   setHeader: true,
          //   label: '출석시간',
          //   style: 'width:100px',
          //   type: 'datetime',
          //   align: 'center',
          //   sortable: false
          // },
          {
            name: 'noAttendanceReason',
            field: 'noAttendanceReason',
            // 불참사유
            label: 'LBL0002869',
            type: 'text',
            align: 'left',
            sortable: false
          },
          {
            name: 'opinionContent',
            field: 'opinionContent',
            // 의견
            label: 'LBL0002925',
            type: 'text',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '400px'
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            label: '소속',
            align: 'center',
            style: 'width:130px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:100px',
            // 직책
            label: 'LBLPOSITION',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'attendeesName',
            field: 'attendeesName',
            // 대상자
            label: 'LBL0002893',
            type: 'text',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          // {
          //   name: 'residentNo',
          //   field: 'residentNo',
          //   // 생년월일
          //   label: 'LBL0002895',
          //   type: 'date',
          //   style: 'width:100px',
          //   align: 'center',
          //   sortable: false
          // },
          {
            name: 'checkCompleteFlag',
            field: 'checkCompleteFlag',
            setHeader: true,
            // 이수여부
            label: 'LBL0002920',
            type: 'select',
            style: 'width:100px',
            align: 'center',
            comboItems: [
              { code: 'Y', codeName: '이수' },
              { code: 'N', codeName: '미이수' },
            ],
            sortable: false,
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            setHeader: true,
            // 이수일
            label: 'LBL0002868',
            type: 'date',
            style: 'width:110px',
            align: 'center',
            sortable: false
          },
          // {
          //   name: 'click',
          //   field: 'click',
          //   label: '서명',
          //   style: 'width:60px',
          //   type: 'custom',
          //   align: 'center',
          //   sortable: false,
          //   component: () => import(`${'./signaturePop.vue'}`)
          // },
          // {
          //   name: 'electronSignatureFlag',
          //   field: 'electronSignatureFlag',
          //   label: '서명여부',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'evaluationPoint',
            field: 'evaluationPoint',
            // 평가점수
            label: 'LBL0002866',
            type: 'number',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationContent',
            field: 'evaluationContent',
            // 평가내용
            label: 'LBL0002867',
            type: 'text',
            style: 'width:230px',
            align: 'center',
            sortable: false
          },
          // {
          //   name: 'attendanceTime',
          //   field: 'attendanceTime',
          //   label: '출석시간',
          //   setHeader: true,
          //   type: 'datetime',
          //   style: 'width:100px',
          //   align: 'center',
          //   sortable: false
          // },
          {
            name: 'noAttendanceReason',
            field: 'noAttendanceReason',
            // 불참사유
            label: 'LBL0002869',
            type: 'text',
            style: 'width:240px',
            align: 'center',
            sortable: false
          },
          {
            name: 'opinionContent',
            field: 'opinionContent',
            // 의견
            label: 'LBL0002925',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false
          },
        ],
        data: [],
        height: '250px'
      },
      editable: true,
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
      updateUrl: '',
      insertUrl: '',
      deleteUrl: '',
      searchUrl: '',
      items: [],
      getUrl: '',
      rowIndex: 0,
      electronSignature: '',
    };
  },
  computed: {
    disabled() {
      return this.educationInfo.documentStatusCd === 'ESC000010'
        || this.educationInfo.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
    'educationInfo'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.targetUser.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.targetUser.url;
      this.getAttendUrl = selectConfig.sop.edu.result.get.targetUser.url;
      // url setting
      // code setting
      // this.getAttendeeList()
      // list setting
    },
    getDetail() {
      this.$set(this.attachInfo, 'taskKey', this.educationInfo.eduEducationId)
      if (this.educationInfo.educationMethodCd == 'EMC999999') { // 동영상교육
        this.grid.columns.splice(9, 0, {
          name: "vodPlayTime",
          field: "vodPlayTime",
          // 동영상 시청시간
          label: "LBL0002923",
          align: "center",
          style: 'width:180px',
          sortable: true,
          type: 'custom'
        })
      }
      if (this.educationInfo.eduQuestionFlag == 'Y') { // 문제풀이 여부
        this.grid.columns.push({
          name: 'questionScore',
          field: 'questionScore',
          // 문제풀이점수
          label: 'LBL0002926',
          style: 'width:100px',
          align: 'center',
          sortable: false
        },
        {
          name: 'questionScoreDt',
          field: 'questionScoreDt',
          // 문제풀이 일시
          label: 'LBL0002927',
          style: 'width:180px',
          align: 'center',
          sortable: false
        })
      }
      // if (this.educationInfo.eduEducationId) {
      //   this.educationInfo.eduAttendeeList = [];
      //   this.grid2.data = [];
      //   this.$_.forEach(this.educationInfo.eduAttendeeList, _item => {
      //     if (_item.inExTypeCd === 'EX') {
      //       this.grid2.data.push(_item) // 외부인력
      //     } else {
      //       this.educationInfo.eduAttendeeList.push(_item) // 선임
      //     }
      //   })
      // }
    },
    getPlayTime(_row) {
      if (_row.vodTotalTime === null) {
        return '시청 전';
      } else {
        let m1 = parseInt(_row.vodPlayTime / 60);
        let s1 = parseInt(_row.vodPlayTime % 60);
        let m2 = parseInt(_row.vodTotalTime / 60);
        let s2 = parseInt(_row.vodTotalTime % 60);
        return m1 + '분 ' + s1 + '초' + ' / ' + m2 + '분 ' + s2 + '초'
      }
    },
    getPlayTimeColor(_min) {
      if (_min === null) {
        return 'grey-6';
      } else {
        return 'blue'
      }
    },
    // getAttendeeList() {
    //   if (this.param.eduEducationId) {
    //     this.$set(this.attachInfo, 'taskKey', this.param.eduEducationId)
    //     this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
    //     this.$http.type = 'GET';
    //     this.$http.request((_result) => {
    //       this.educationInfo = _result.data;
    //       this.educationInfo.eduAttendeeList = [];
    //       this.grid2.data = [];
    //       this.$_.forEach(this.educationInfo.eduAttendeeList, _item => {
    //         if (_item.inExTypeCd === 'EX') {
    //           this.grid2.data.push(_item) 
    //         } else {
    //           this.educationInfo.eduAttendeeList.push(_item) 
    //         }
    //       })
    //       if (_result.data.educationMethodCd == 'EMC999999') { // 동영상교육
    //         this.grid.columns.splice(9, 0, {
    //           name: "vodPlayTime",
    //           field: "vodPlayTime",
    //           // 동영상 시청시간
    //           label: "LBL0002923",
    //           align: "center",
    //           style: 'width:180px',
    //           sortable: true,
    //           type: 'custom'
    //         })
    //       }
    //       if (_result.data.eduQuestionFlag == 'Y') { // 문제풀이 여부
    //         this.grid.columns.push({
    //           name: 'questionScore',
    //           field: 'questionScore',
    //           // 문제풀이점수
    //           label: 'LBL0002926',
    //           style: 'width:100px',
    //           align: 'center',
    //           sortable: false
    //         },
    //         {
    //           name: 'questionScoreDt',
    //           field: 'questionScoreDt',
    //           // 문제풀이 일시
    //           label: 'LBL0002927',
    //           style: 'width:180px',
    //           align: 'center',
    //           sortable: false
    //         })
    //       }
    //     },);
    //   } 
    // },
    saveEducation1() {
      let invalidItemExists = false;
      invalidItemExists = this.$_.some(this.educationInfo.eduAttendeeList, _item => {
        return _item.checkCompleteFlag === 'Y' && (_item.evaluationDate === null || _item.evaluationDate === '');
      });
      if (!invalidItemExists) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.educationInfo.eduAttendeeList, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            this.isSave1 = !this.isSave1;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '이수완료 처리한 대상자의 이수일을 입력하세요.', 
          type: 'warning', // success / info / warning / error
        });
      }
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.educationInfo.eduAttendeeList, item => {
        item.editFlag = 'U'
        if (item.electronSignature) item.electronSignatureFlag = '서명'
        else item.electronSignatureFlag = '미서명'
      })
      this.planUpdateBtnData.research = uid();
      // this.getAttendeeList();
      this.$emit('setData', this.educationInfo.eduEducationId)
    },
    saveEducation2() {
      let invalidItemExists2 = false
      invalidItemExists2 = this.$_.some(this.grid2.data, _item => {
        return _item.checkCompleteFlag === 'Y' && (_item.evaluationDate === null || _item.evaluationDate === '');
      });
      if (!invalidItemExists2) {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid2.data, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId
          })
          
          this.isSave2 = !this.isSave2;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '이수완료 처리한 대상자의 이수일을 입력하세요.', 
          type: 'warning', // success / info / warning / error
        });
      }
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.grid2.data, item => {
        item.editFlag = 'U'
        if (item.electronSignature) item.electronSignatureFlag = '서명'
        else item.electronSignatureFlag = '미서명'
      })
      this.planUpdateBtnData.research = uid();
      // this.getAttendeeList();
      this.$emit('setData', this.educationInfo.eduEducationId)
    },
    addItem() {
      this.popupOptions.title = "LBL0002898"; // 교육대상자 검색
      this.popupOptions.param = {
        type: 'multiple',
        targetClassCd: this.targetClassCd,
      };
      this.popupOptions.target = () => import(`${'./educationCompletePop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closeTargetUserPopup;
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {
            attendeesId: _item.userId,
          });
          if (index === -1) {
            this.educationInfo.eduAttendeeList.splice(0, 0, {
              attendeesNo: uid(),
              attendeesTypeCd: 'EATC00003',
              attendeesDeptName: _item.deptName,
              attendeesPositionName: _item.jobName,
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              inExTypeCd: 'IN',
              eduEducationId: this.educationInfo.eduEducationId,
              seniorityName: _item.seniorName,
              checkCompleteFlag: null,
              electronSignature: '',
              evaluationPoint: null,
              evaluationContent: null,
              evaluationDate: null,
              attendanceTime: '',
              noAttendanceReason: '',
              opinionContent: '',
              checkCompleteFlagName: '미이수',
              electronSignatureFlag: '미서명',
              vodTotalTime: null,
              vodPlayTime: null,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },  
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGREMOVE', // 삭제하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.deleteUrl;
              this.$http.type = 'DELETE';
              this.$http.param = {
                data: Object.values(selectData)
              }
              this.$http.request(() => {
                this.$_.forEach(selectData, item => {
                  this.educationInfo.eduAttendeeList = this.$_.reject(this.educationInfo.eduAttendeeList, { attendeesNo: item.attendeesNo })
                })
                this.planUpdateBtnData.research = uid();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addItem4() {
      this.grid2.data.splice(0, 0, {
        attendeesNo: uid(),
        attendeesTypeCd: 'EATC00004',
        attendeesDeptName: '',
        attendeesPositionName: '',
        eduEducationId: this.educationInfo.eduEducationId,
        attendeesId: '',
        attendeesName: '',
        checkCompleteFlag: null,
        inExTypeCd: 'EX',
        evaluationPoint: null,
        evaluationContent: null,
        evaluationDate: null,
        attendanceTime: '',
        noAttendanceReason: '',
        opinionContent: '',
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      })
    },
    removeItem4() {
      let selectData = this.$refs['table4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, { attendeesNo: item.attendeesNo })
              })
              this.planUpdateBtnData.research = uid();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
    },
    signCallback(data, rowIndex) {
      this.educationInfo.eduAttendeeList[rowIndex].electronSignature = data
      if(this.educationInfo.eduAttendeeList[rowIndex].editFlag !== 'C') {
        this.educationInfo.eduAttendeeList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
    callback2(data, rowIndex) {
      let refName = 'proxy2_' + rowIndex;
      this.$refs[refName].hide();
    },
    customColor(data, type) {
      if (type === 'EATC00001') {
        return 'primary';
      } else if (type === 'EATC00002') {
        return 'red';
      } else if (type === 'EATC00003') {
        return 'orange';
      } else {
        return 'green';
      }
    }
  }
};
</script>