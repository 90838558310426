var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "교육대상자",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.educationInfo.eduAttendeeList,
                filtering: false,
                checkClickFlag: false,
                columnSetting: false,
                merge: _vm.grid.merge,
                usePaging: false,
                hideBottom: true,
                editable: _vm.editable && !_vm.disabled,
                isExcelDown: false,
                rowKey: "attendeesNo",
                selection: "multiple",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "click"
                        ? [
                            _c(
                              "q-btn",
                              {
                                attrs: {
                                  round: "",
                                  unelevated: "",
                                  size: "10px",
                                  color: "amber",
                                  icon: "edit",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() => {
                                      ;(_vm.rowIndex = props.rowIndex),
                                        (_vm.electronSignature =
                                          props.row.electronSignature)
                                    }).apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c(
                                  "q-popup-proxy",
                                  {
                                    ref: "proxy1_" + props.rowIndex,
                                    attrs: { breakpoint: 400 },
                                  },
                                  [
                                    _c(col.component, {
                                      tag: "component",
                                      attrs: {
                                        popupParam: props.row,
                                        rowIndex: props.rowIndex,
                                      },
                                      on: { signCallback: _vm.signCallback },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      col.name === "vodPlayTime"
                        ? [
                            _c(
                              "q-chip",
                              {
                                attrs: {
                                  color: _vm.getPlayTimeColor(
                                    props.row.vodTotalTime
                                  ),
                                  outline: "",
                                  square: "",
                                  clickable: false,
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getPlayTime(props.row)) + " "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLADD",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.educationInfo.eduAttendeeList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLREMOVE",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.educationInfo.eduAttendeeList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.insertUrl,
                              isSubmit: _vm.isSave1,
                              param: _vm.educationInfo.eduAttendeeList,
                              mappingType: "PUT",
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveEducation1,
                              btnCallback: _vm.saveCallback1,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sign" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0002918" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable && !_vm.disabled,
                        label: "LBL0002918",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }